<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import { Carousel, Slide, Navigation } from "vue3-carousel";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const {
  Slides,
  boxImageOne,
  boxImageTwo,
  boxImageThree,
  boxImageFour,
  boxTextOne,
  boxTextTwo,
  boxTextThree,
  boxTextFour,
  boxButtonOne,
  boxButtonTwo,
  boxButtonThree,
  boxButtonFour,
} = props.content.data;

const settings = {
  itemsToShow: 1,
  itemsToScroll: 1,
  wrapAround: false,
  autoplay: 0,
  transition: 600,
  pauseAutoplayOnHover: true,
  preventExcessiveDragging: true,
  snapAlign: "center",
};

const productSlider = ref(null);
const currentSlide = ref(0);
</script>
<template>
  <section class="relative bg-skin-theme overflow-hidden">
    <div class="relative z-10 pointer-events-none pb-[60px] -mt-[60px]">
      <div
        id="BannerCarousel"
        class="relative [&>.slick-list]:overflow-visible"
      >
        <Carousel v-bind="settings" ref="productSlider" v-model="currentSlide">
          <template #slides>
            <Slide v-for="slide of Slides" :key="slide">
              <div
                class="slide min-h-[37.5rem] min-w-[100vw] relative !flex items-center text-skin-white md:px-16 laptop:px-0 w-full"
              >
                <NuxtImg
                  format="webp"
                  loading="lazy"
                  v-if="slide.slideBackground && slide.slideBackground.media"
                  :src="slide.slideBackground.media.url"
                  alt=""
                  class="w-full h-full object-cover absolute top-0 left-0 -z-[1]"
                />
                <div class="container relative z-1 max-w-[73rem] ">
                  <div
                    class="w-full md:w-2/4 prose-p:text-skin-white prose-p:opacity-70 text-left"
                  >
                    <h6
                      class="sub-title text-skin-icon text-left"
                      v-html="slide.slideToplineText"
                    ></h6>
                    <h1
                      class="font-bold text-3xl laptop:text-[3.125rem]/[3rem] uppercase tracking-[0.125rem] text-left"
                      v-html="slide.slideHeadlineText"
                    ></h1>
                    <div class="mb-4 mt-2" v-html="slide.slideText"></div>
                    <SharedLink
                      :button="slide.slideLink"
                      class="btn pointer-events-auto"
                    >
                      <template #link>{{ slide.slideLink.title }}</template>
                    </SharedLink>
                  </div>
                  <NuxtImg
                    v-if="slide.slideImage && slide.slideImage.media"
                    format="webp"
                    loading="eager"
                    :src="slide.slideImage.media.url"
                    :alt="slide.slideImage.media.metaData.alt"
                    :width="slide.slideImage.media.metaData.width"
                    :height="slide.slideImage.media.metaData.height"
                    class="max-w-[32.5rem] absolute top-2/4 -translate-y-[38%] translate-x-16 right-0 !hidden laptop:!block pointer-events-none z-10"
                  />
                </div>
                <NuxtImg
                  format="webp"
                  loading="eager"
                  src="/images/layer1.png"
                  alt="layer1"
                  class="max-w-[50%] hidden lg:block absolute right-0 -top-8 pointer-events-none z-10"
                />
              </div>
            </Slide>
          </template>
          <template #addons>
            <div
              class="carousel__navigation-button z-0 absolute bottom-0 md:bottom-auto md:top-2/4 max-w-[100rem] flex justify-between left-0 right-0 mx-auto px-3 laptop:px-10 [&>button]:static"
            >
              <Navigation>
                <template #next>
                  <img
                    src="assets/icons/slide-right.svg"
                    alt="Next"
                    class="w-6 h-6"
                  />
                </template>
                <template #prev>
                  <img
                    src="assets/icons/slide-left.svg"
                    alt="Previous"
                    class="w-6 h-6"
                  />
                </template>
              </Navigation>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
    <div
      class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 text-skin-white text-center [&>.flex]:min-h-[5.75rem] md:[&>.flex]:min-h-[9.6rem] [&>.flex:not(:last-child)]:border-r [&>.flex:not(:last-child)]:border-skin-theme-light relative z-0 -mt-[60px] bg-skin-theme text-skin-white"
    >
      <SharedLink
        v-if="boxButtonOne"
        class="flex items-center justify-center flex-col border-b border-skin-theme-light md:border-b-0"
        :button="boxButtonOne"
      >
        <template #link>
          <div
            class="h-8 prose-img:max-h-full flex justify-center items-center mb-2.5"
          >
            <NuxtImg
              v-if="boxImageOne && boxImageOne.media"
              format="webp"
              loading="eager"
              :width="boxImageOne.media.metaData.width"
              :height="boxImageOne.media.metaData.height"
              :src="boxImageOne.media.url"
              alt=""
            />
          </div>
          <h6 class="text-sm font-bold">{{ boxTextOne }}</h6>
        </template>
      </SharedLink>
      <SharedLink
        v-if="boxButtonTwo"
        class="flex items-center justify-center flex-col border-b border-skin-theme-light md:border-b-0"
        :button="boxButtonTwo"
      >
        <template #link>
          <div
            class="h-8 prose-img:max-h-full flex justify-center items-center mb-2.5"
          >
            <NuxtImg
              v-if="boxImageTwo && boxImageTwo.media"
              format="webp"
              loading="lazy"
              :width="boxImageTwo.media.metaData.width"
              :height="boxImageTwo.media.metaData.height"
              :src="boxImageTwo.media.url"
              densities="x1"
              alt=""
            />
          </div>
          <h6 class="text-sm font-bold">{{ boxTextTwo }}</h6>
        </template>
      </SharedLink>
      <SharedLink
        v-if="boxButtonThree"
        class="flex items-center justify-center flex-col border-b border-skin-theme-light md:border-b-0"
        :button="boxButtonThree"
      >
        <template #link>
          <div
            class="h-8 prose-img:max-h-full flex justify-center items-center mb-2.5"
          >
            <NuxtImg
              v-if="boxImageThree && boxImageThree.media"
              format="webp"
              loading="lazy"
              :width="boxImageThree.media.metaData.width"
              :height="boxImageThree.media.metaData.height"
              :src="boxImageThree.media.url"
              alt=""
            />
          </div>
          <h6 class="text-sm font-bold">{{ boxTextThree }}</h6>
        </template>
      </SharedLink>
      <SharedLink
        v-if="boxButtonFour"
        class="flex items-center justify-center flex-col"
        :button="boxButtonFour"
      >
        <template #link>
          <div
            class="h-8 prose-img:max-h-full flex justify-center items-center mb-2.5"
          >
            <NuxtImg
              v-if="boxImageFour && boxImageFour.media"
              format="webp"
              loading="lazy"
              :width="boxImageFour.media.metaData.width"
              :height="boxImageFour.media.metaData.height"
              :src="boxImageFour.media.url"
              alt=""
            />
          </div>
          <h6 class="text-sm font-bold">{{ boxTextFour }}</h6>
        </template>
      </SharedLink>
    </div>
  </section>
</template>

<style scoped>
.carousel__navigation-button :deep(.carousel__prev),
.carousel__navigation-button :deep(.carousel__next) {
  @apply bg-skin-icon text-skin-white w-12 h-12 rounded-full flex items-center justify-center mr-0;
  pointer-events: all;
}

.carousel__navigation-button {
  z-index: 0;
  pointer-events: none;
}

.carousel :deep(.carousel__slide) {
  min-width: 100vw;
}

.carousel :deep(.carousel__viewport) {
  overflow-y: visible;
  overflow-x: visible;
}
</style>
